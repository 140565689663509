.paperContainer {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 20px;
}

.paperContainer:hover {
  background-color: #cbcbcb;
}