.user-item {
  background-color: white;
}

.user-item :hover {
  background-color: #cecece;
}

.user-item-text {
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}