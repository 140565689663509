.fixed-bottom {
  position: fixed;
  bottom: 0;
  right: 50px;
}

.chat-container {
  z-index: 3000;
  background-color: #0e59a8c0;
  backdrop-filter: blur(2px);
  padding: 10px;
  width: 400px;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.fixed-bottom-chat-list {
  position: fixed;
  bottom: 0px;
  right: 455px;
}

.chat-list-container {
  z-index: 2900;
  background-color: #0e59a8c0;
  backdrop-filter: blur(2px);
  padding: 10px;
  width: 300px;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.chat-list-container-animate-enter {
  animation: 0.10s ease-in 0s 1 running slideLeftChatListEnter;
}

@keyframes slideLeftChatListEnter {
  from {
    transform: translateX(400px);
  }

  to {
    transform: translateX(0);
  }
}

.chat-icon {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;

  border-radius: 30%;

  background-color: #ffffffdd;

  z-index: 3000;
}

.chat-icon:hover {
  background-color: #0e59a850;
}

.main-container {
  min-height: 400px;
  max-height: 400px;
  height: 400px;
  background-color: white;
  padding: 10px;

  border: solid 1px #cecece;
  border-radius: 5px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.tooltip {
  font-size: 11px;
}